import React, { Component } from 'react'

import DetailsLightBoxImage from '../../assets/images/details-lightbox-1.png'
import './style.css'

class LightBox extends Component {
  render() {
    return (
      <div
        id='details-lightbox-1'
        className='lightbox-basic zoom-anim-dialog mfp-hide'
      >
        <div className='row'>
          <button
            title='Close (Esc)'
            type='button'
            className='mfp-close x-button'
          >
            ×
          </button>
          <div className='col-lg-6'>
            <img
              className='img-fluid'
              src={DetailsLightBoxImage}
              alt='alternative'
            />
          </div>
          <div className='col-lg-6'>
            <h3>Goals Setting</h3>
            <hr />
            <p>
              At Mocha we’re building tools to help you look at yourself & be
              who you want to be. (You can think of us as the Strava of
              self-honesty)
            </p>
            <h4>Who are we, really?</h4>
            <p>Our goal at Mocha is to support you in:</p>
            <table>
              <tbody>
                <tr>
                  <td className='icon-cell'>
                    <i className='fas fa-desktop'></i>
                  </td>
                  <td>Adversity Toolset</td>
                </tr>
                <tr>
                  <td className='icon-cell'>
                    <i className='fas fa-bullhorn'></i>
                  </td>
                  <td>Needs Toolset</td>
                </tr>
                <tr>
                  <td className='icon-cell'>
                    <i className='fas fa-image'></i>
                  </td>
                  <td>Character Graph Toolset</td>
                </tr>
                <tr>
                  <td className='icon-cell'>
                    <i className='fas fa-envelope'></i>
                  </td>
                  <td>Character Map Toolset</td>
                </tr>
              </tbody>
            </table>
            <p>
              Whether introspection is easy or difficult, these tools can help
              you find clarity, control, and serenity about your life, your fit,
              and your path.
            </p>
            <a className='btn-solid-reg' href='#your-link'>
              DETAILS
            </a>{' '}
            <a className='btn-outline-reg mfp-close as-button' href='#details'>
              BACK
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export default LightBox
