import React, { Component } from 'react'

import VideoImage from '../../assets/images/video-frame.jpg'
import './style.css'

class Footer extends Component {
  render() {
    return (
      <div id='preview' className='basic-1'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <h2>PREVIEW</h2>
              <div className='p-heading p-large'>
                Target the right customers for your business with the help of
                Mocha's patented segmentation technology
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='image-container'>
                <div className='video-wrapper'>
                  <a
                    className='popup-youtube'
                    href='https://www.youtube.com/watch?v=fLCjQJCekTs'
                    data-effect='fadeIn'
                  >
                    <img
                      className='img-fluid'
                      src={VideoImage}
                      alt='alternative'
                    />
                    <span className='video-play-button'>
                      <span />
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Footer
