import React, { useState } from 'react'

import './style.css'

const Contact = () => {
  const [email, setEmail] = useState('')

  const handleInput = e => {
    setEmail(e.target.value)
  }

  return (
    <div id='contact' className='form'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <h2>Sign Up</h2>
            <ul className='list-unstyled li-space-lg'>
              <li className='address'>
                Don't hesitate to give us a call or just use the contact form
                below
              </li>
              <li>
                <i className='fas fa-map-marker-alt'></i>22 Innovative, San
                Francisco, CA 94043, US
              </li>
              <li>
                <i className='fas fa-phone'></i>
                <a className='blue' href='tel:003024630820'>
                  +81 720 2212
                </a>
              </li>
              <li>
                <i className='fas fa-envelope'></i>
                <a className='blue' href='mailto:office@leno.com'>
                  office@leno.com
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-6 offset-lg-3'>
            <form
              id='contactForm'
              data-toggle='validator'
              data-focus='false'
              action='https://gmail.us3.list-manage.com/subscribe/post?u=6d7137e3d1fb755b4b32dba4b&amp;id=8ab7390d0c'
              method='post'
              name='mc-embedded-subscribe-form'
              className='validate'
              target='_blank'
            >
              <div className='form-group'>
                <input
                  type='email'
                  className='form-control-input'
                  id='cemail'
                  onChange={handleInput}
                  value={email}
                  required
                />
                <label className='label-control' htmlFor='cemail'>
                  Email
                </label>
                <div className='help-block with-errors'></div>
              </div>

              <div className='form-group'>
                Sign up to be among the first to gain access to Mocha. Already a
                member? Follow the link to our Alpha Program Portal.
                <button
                  type='submit'
                  className='form-control-submit-button mt-3'
                >
                  SUBMIT
                </button>
              </div>
              <div className='form-message'>
                <div id='cmsgSubmit' className='h3 text-center hidden'></div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
