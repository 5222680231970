import React, { Component } from 'react'

import {
  Preview,
  Features,
  Header,
  NavBar,
  Contact,
  Footer,
  Download,
  ScreenShots,
  LightBox,
  Details,
} from '../components'
import '../styles/styles.css'

class App extends Component {
  componentDidMount() {
    const script = document.createElement('script')
    script.src = 'js/scripts.js'
    script.async = true
    document.body.appendChild(script)
  }

  render() {
    return (
      <div className='App'>
        <NavBar />
        <Header />
        <Details />
        <LightBox />
        <Features />
        <Preview />
        <ScreenShots />
        <Download />
        <Contact />
        <Footer />
      </div>
    )
  }
}

export default App
