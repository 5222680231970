import screenshot1 from '../assets/images/screenshot-1.png'
import screenshot2 from '../assets/images/screenshot-2.png'
import screenshot3 from '../assets/images/screenshot-3.png'
import screenshot4 from '../assets/images/screenshot-4.png'
import screenshot5 from '../assets/images/screenshot-5.png'
import screenshot6 from '../assets/images/screenshot-6.png'
import screenshot7 from '../assets/images/screenshot-7.png'
import screenshot8 from '../assets/images/screenshot-8.png'
import screenshot9 from '../assets/images/screenshot-9.png'
import screenshot10 from '../assets/images/screenshot-10.png'

export const screenshots = [
  screenshot1,
  screenshot2,
  screenshot3,
  screenshot4,
  screenshot5,
  screenshot6,
  screenshot7,
  screenshot8,
  screenshot9,
  screenshot10,
]
