import React, { Component, Fragment } from 'react'

import DetailsImageFirst from '../../assets/images/details-1-iphone.png'
import DetailsImageSecond from '../../assets/images/details-2-iphone.png'

class Details extends Component {
  render() {
    return (
      <Fragment>
        <div id='details' className='basic-2'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-6'>
                <img
                  className='img-fluid'
                  src={DetailsImageFirst}
                  alt='alternative'
                />
              </div>
              <div className='col-lg-6'>
                <div className='text-container'>
                  <h3>Goals Setting</h3>
                  <p>
                    Mocha can easily help you track your personal development
                    evolution if you take the time to properly setup your goals
                    at the beginning of the training process. Check out the
                    details
                  </p>
                  <a
                    className='btn-solid-reg popup-with-move-anim'
                    href='#details-lightbox-1'
                  >
                    LIGHTBOX
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='basic-3'>
          <div className='second'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-6'>
                  <div className='text-container'>
                    <p>
                      Our tools are currently in development. If you would like
                      to contribute to our efforts, please click above.
                    </p>
                    <a
                      className='btn-solid-reg '
                      href='https://adversity-tracker.herokuapp.com/?#/login'
                    >
                      Support us on Patreon
                    </a>
                  </div>
                </div>
                <div className='col-lg-6'>
                  <img
                    className='img-fluid'
                    src={DetailsImageSecond}
                    alt='alternative'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default Details
