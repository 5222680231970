import React, { Component } from 'react'

import './style.css'
import { screenshots } from '../../assets/images'

class ScreenShots extends Component {
  renderScreenShots = () => {
    const ssComponents = []
    for (let i = 0; i < 11; i++) {
      ssComponents.push(
        <div className='swiper-slide' key={i}>
          <a href={screenshots[1]} className='popup-link' data-effect='fadeIn'>
            <img className='img-fluid' src={screenshots[1]} alt='alternative' />
          </a>
        </div>
      )
    }
    return ssComponents
  }
  render() {
    return (
      <div className='slider-2'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='slider-container'>
                <div className='swiper-container image-slider'>
                  <div className='swiper-wrapper'>
                    {this.renderScreenShots()}
                  </div>
                  <div className='swiper-button-next'></div>
                  <div className='swiper-button-prev'></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ScreenShots
