import React, { Component } from 'react'

import FeatureImageOne from '../../assets/images/features-iphone-1.png'
import FeatureImageTwo from '../../assets/images/features-iphone-2.png'

import './style.css'

class Features extends Component {
  render() {
    return (
      <div id='features' className='tabs'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <h2>FEATURES</h2>
              <div className='p-heading p-large'>
                Mocha was designed based on input from personal development
                coaches and popular trainers so it offers all
              </div>
            </div>
          </div>
          <div className='row'>
            <ul className='nav nav-tabs' id='lenoTabs' role='tablist'>
              <li className='nav-item'>
                <a
                  className='nav-link active'
                  id='nav-tab-1'
                  data-toggle='tab'
                  href='#tab-1'
                  role='tab'
                  aria-controls='tab-1'
                  aria-selected='true'
                >
                  <i className='fas fa-cog'></i>CONFIGURING
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className='nav-link'
                  id='nav-tab-2'
                  data-toggle='tab'
                  href='#tab-2'
                  role='tab'
                  aria-controls='tab-2'
                  aria-selected='false'
                >
                  <i className='fas fa-binoculars'></i>TRACKING
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className='nav-link'
                  id='nav-tab-3'
                  data-toggle='tab'
                  href='#tab-3'
                  role='tab'
                  aria-controls='tab-3'
                  aria-selected='false'
                >
                  <i className='fas fa-search'></i>MONITORING
                </a>
              </li>
            </ul>
            <div className='tab-content' id='lenoTabsContent'>
              <div
                className='tab-pane fade show active'
                id='tab-1'
                role='tabpanel'
                aria-labelledby='tab-1'
              >
                <div className='container'>
                  <div className='row'>
                    <div className='col-lg-4'>
                      <div className='card left-pane first'>
                        <div className='card-body'>
                          <div className='text-wrapper'>
                            <h4 className='card-title'>Goal Setting</h4>
                            <p>
                              Like any self improving process, everything starts
                              with setting your goals and committing to them
                            </p>
                          </div>
                          <div className='card-icon'>
                            <i className='far fa-compass'></i>
                          </div>
                        </div>
                      </div>
                      <div className='card left-pane'>
                        <div className='card-body'>
                          <div className='text-wrapper'>
                            <h4 className='card-title'>Visual Editor</h4>
                            <p>
                              Mocha provides a well designed and ergonomic
                              visual editor for you to edit your notes and input
                              data
                            </p>
                          </div>
                          <div className='card-icon'>
                            <i className='far fa-file-code'></i>
                          </div>
                        </div>
                      </div>
                      <div className='card left-pane'>
                        <div className='card-body'>
                          <div className='text-wrapper'>
                            <h4 className='card-title'>Refined Options</h4>
                            <p>
                              Each option packaged in the app's menus is
                              provided in order to improve your personal
                              development status
                            </p>
                          </div>
                          <div className='card-icon'>
                            <i className='far fa-gem'></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4'>
                      <img
                        className='img-fluid'
                        src={FeatureImageOne}
                        alt='alternative'
                      />
                    </div>
                    <div className='col-lg-4'>
                      <div className='card right-pane first'>
                        <div className='card-body'>
                          <div className='card-icon'>
                            <i className='far fa-calendar-check'></i>
                          </div>
                          <div className='text-wrapper'>
                            <h4 className='card-title'>Calendar Input</h4>
                            <p>
                              Schedule your appointments, meetings and
                              periodical evaluations using the provided in-app
                              calendar option
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className='card right-pane'>
                        <div className='card-body'>
                          <div className='card-icon'>
                            <i className='far fa-bookmark'></i>
                          </div>
                          <div className='text-wrapper'>
                            <h4 className='card-title'>Easy Reading</h4>
                            <p>
                              Reading focus mode for long form articles, ebooks
                              and other materials which involve large text areas
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className='card right-pane'>
                        <div className='card-body'>
                          <div className='card-icon'>
                            <i className='fas fa-cube'></i>
                          </div>
                          <div className='text-wrapper'>
                            <h4 className='card-title'>Good Foundation</h4>
                            <p>
                              Get a solid foundation for your self development
                              efforts. Try Mocha mobile app for any mobile
                              platform
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className='tab-pane fade'
                id='tab-2'
                role='tabpanel'
                aria-labelledby='tab-2'
              >
                <div className='container'>
                  <div className='row'>
                    <div className='col-md-4'>
                      <img
                        className='img-fluid'
                        src={FeatureImageTwo}
                        alt='alternative'
                      />
                    </div>

                    <div className='col-md-8'>
                      <div className='text-area'>
                        <h3>Track Result Based On Your</h3>
                        <p>
                          After you've configured the app and settled on the
                          data gathering techniques you can not start the
                          information trackers and start collecting those{' '}
                          <a className='turquoise' href='#your-link'>
                            interesting details
                          </a>
                          . You can always change them.
                        </p>
                      </div>

                      <div className='icon-cards-area'>
                        <div className='card'>
                          <div className='card-icon'>
                            <i className='fas fa-cube'></i>
                          </div>
                          <div className='card-body'>
                            <h4 className='card-title'>Good Foundation</h4>
                            <p>
                              Get a solid foundation for your self development
                              efforts. Try Mocha mobile app for any mobile
                              platform
                            </p>
                          </div>
                        </div>
                        <div className='card'>
                          <div className='card-icon'>
                            <i className='far fa-bookmark'></i>
                          </div>
                          <div className='card-body'>
                            <h4 className='card-title'>Easy Reading</h4>
                            <p>
                              Reading focus mode for long form articles, ebooks
                              and other materials which involve large text areas
                            </p>
                          </div>
                        </div>
                        <div className='card'>
                          <div className='card-icon'>
                            <i className='far fa-calendar-check'></i>
                          </div>
                          <div className='card-body'>
                            <h4 className='card-title'>Calendar Input</h4>
                            <p>
                              Schedule your appointments, meetings and
                              periodical evaluations using the provided in-app
                              calendar option
                            </p>
                          </div>
                        </div>
                        <div className='card'>
                          <div className='card-icon'>
                            <i className='far fa-file-code'></i>
                          </div>
                          <div className='card-body'>
                            <h4 className='card-title'>Visual Editor</h4>
                            <p>
                              Mocha provides a well designed and ergonomic
                              visual editor for you to edit your notes and input
                              data
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className='tab-pane fade'
                id='tab-3'
                role='tabpanel'
                aria-labelledby='tab-3'
              >
                <div className='container'>
                  <div className='row'>
                    <div className='col-md-8'>
                      <div className='icon-cards-area'>
                        <div className='card'>
                          <div className='card-icon'>
                            <i className='far fa-calendar-check'></i>
                          </div>
                          <div className='card-body'>
                            <h4 className='card-title'>Calendar Input</h4>
                            <p>
                              Schedule your appointments, meetings and
                              periodical evaluations using the provided in-app
                              calendar option
                            </p>
                          </div>
                        </div>
                        <div className='card'>
                          <div className='card-icon'>
                            <i className='far fa-file-code'></i>
                          </div>
                          <div className='card-body'>
                            <h4 className='card-title'>Visual Editor</h4>
                            <p>
                              Mocha provides a well designed and ergonomic
                              visual editor for you to edit your notes and input
                              data
                            </p>
                          </div>
                        </div>
                        <div className='card'>
                          <div className='card-icon'>
                            <i className='fas fa-cube'></i>
                          </div>
                          <div className='card-body'>
                            <h4 className='card-title'>Good Foundation</h4>
                            <p>
                              Get a solid foundation for your self development
                              efforts. Try Mocha mobile app for any mobile
                              platform
                            </p>
                          </div>
                        </div>
                        <div className='card'>
                          <div className='card-icon'>
                            <i className='far fa-bookmark'></i>
                          </div>
                          <div className='card-body'>
                            <h4 className='card-title'>Easy Reading</h4>
                            <p>
                              Reading focus mode for long form articles, ebooks
                              and other materials which involve large text areas
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className='text-area'>
                        <h3>Monitoring Tools Evaluation</h3>
                        <p>
                          Monitor the evolution of your finances and health
                          state using tools integrated in Mocha. The generated
                          real time reports can be filtered based on any
                          <a className='turquoise' href='#your-link'>
                            desired criteria
                          </a>
                          .
                        </p>
                      </div>
                    </div>

                    <div className='col-md-4'>
                      <img
                        className='img-fluid'
                        src={FeatureImageTwo}
                        alt='alternative'
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Features
