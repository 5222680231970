import React, { Component } from 'react'

import './style.css'
import HeaderIPhone from '../../assets/images/header-iphone.png'

class Header extends Component {
  render() {
    return (
      <header id='header' className='header'>
        <div className='header-content'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-6'>
                <div className='text-container'>
                  <h1>
                    Welcome to Mocha{' '}
                    <span id='js-rotating'>Motivation, Character</span>
                  </h1>
                  <p className='p-large'>
                    At Mocha we’re building tools to help you look at yourself &
                    be who you want to be. (You can think of us as the Strava of
                    self-honesty)
                  </p>
                  <a className='btn-solid-lg page-scroll' href='#your-link'>
                    Alpha Program Portal
                  </a>
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='image-container'>
                  <img
                    className='img-fluid'
                    src={HeaderIPhone}
                    alt='HeaderIPhone'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    )
  }
}

export default Header
